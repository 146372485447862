import React from 'react';  
import ReviewMobile from './reviewMobile';
const handleDragStart = (e) => e.preventDefault();
const responsive = {
    0: { items: 1 },
    568: { items: 1},
    768: { items: 1},
    1024: { items: 1 },
};


const Review = () => {
return (
        <>
        <div className="container-fluid review-container desktopView">
            <div className="row">
                <div className="col-md-6 col-sm-12">                    
                    <p className="review-title-para">I was completely surprised and thrilled from the very first time I used their service. They were prompt and friendly, and their work was completed with military precision. I couldn’t be happier with the results, and I am equally satisfied every time I place an order. I’m proud to say that I am a repeat client.</p>
                    <h3 className="review-title-name">Paddy Slyne &nbsp;&nbsp;&nbsp;
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                    </h3>
                    <p className="review-from">Google Review</p>
                </div>
                <div className="col-md-6 col-sm-12">                    
                    <p className="review-title-para">I used them for the first time, and it was absolutely awesome. Their customer service is exceptional—they should teach a course on it! I’ll be a regular customer from now on and highly recommend them.</p>
                        <h3 className="review-title-name">Dave Sullivan &nbsp;&nbsp;&nbsp;
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                            <i className="fa fa-star review-star"></i>
                        </h3>
                    <p className="review-from">Facebook Review</p>
                </div>
            </div>
        </div>
        <div>
          <ReviewMobile />
        </div>
        </>
)}
export default Review;
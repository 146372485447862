import 'date-fns';
import React from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

function DateTimePickerScheduler(props) {
  // Shared style for time buttons with a fixed border so dimensions don’t change
  const buttonStyle = {
    fontSize: '11px',
    padding: '12px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid transparent', // fixed border
    boxSizing: 'border-box'
  };

  // Render only the DatePicker if renderType is "date"
  if (props.renderType === "date") {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div style={{ width: '60%', display: 'inline-block' }}>
          <DatePicker
            label="Pickup Date"
            inputVariant="outlined"
            value={props.value}
            minDate={moment().toDate()}
            format="ddd, MMM D"
            minutesStep={5}
            variant="dialog"
            onChange={props.onChange}
            DialogProps={{ disableScrollLock: true }}
            style={{ width: '100%' }} // Ensure the DatePicker expands fully
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
  // Render only the time buttons if renderType is "times"
  else if (props.renderType === "times") {
    return (
      <div className="bookingSlotRadio" style={{ display: 'flex', width: '100%' }}>
        <button
          value="07:30:00"
          onClick={props.handleShiftChange}
          style={{ ...buttonStyle, flex: 1 }}
          className={`btn square light btn btn-primary booking firstBooking ${
            props.bookingSlot === '07:30:00' ? 'selected' : 'notSelected'
          }`}
        >
          7:30-8:30AM
        </button>

        <button
          value="09:30:00"
          onClick={props.handleShiftChange}
          style={{ ...buttonStyle, flex: 1 }}
          className={`btn square light btn btn-primary booking ${
            props.bookingSlot === '09:30:00' ? 'selected' : 'notSelected'
          }`}
        >
          9:30-10:30AM
        </button>

        <button
          value="11:30:00"
          onClick={props.handleShiftChange}
          style={{ ...buttonStyle, flex: 1 }}
          className={`btn square light btn btn-primary booking lastBooking ${
            props.bookingSlot === '11:30:00' ? 'selected' : 'notSelected'
          }`}
        >
          11:30-12:30PM
        </button>
      </div>
    );
  } else {
    // Fallback – render nothing
    return null;
  }
}

export default DateTimePickerScheduler;

// src/components/GoogleAddress.js
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { Loader } from '@googlemaps/js-api-loader';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export default function GoogleAddress(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [serviceReady, setServiceReady] = React.useState(false);

  // Debug log for API Key (for debugging)
  console.log('Google API Key:', process.env.GATSBY_GOOGLE_API_KEY);

  React.useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.GATSBY_GOOGLE_API_KEY,
      libraries: ['places'],
      version: 'weekly', // optional: use the weekly channel
    });

    loader.load().then(() => {
      if (
        window.google &&
        window.google.maps &&
        window.google.maps.places
      ) {
        if (!window.autocompleteServiceInstance) {
          window.autocompleteServiceInstance = new window.google.maps.places.AutocompleteService();
        }
        setServiceReady(true);
        console.log("AutocompleteService is ready", window.autocompleteServiceInstance);
      }
    }).catch((err) => {
      console.error("Error loading Google Maps API", err);
    });
  }, []);

  // Throttle calls to getPlacePredictions.
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        if (window.autocompleteServiceInstance) {
          window.autocompleteServiceInstance.getPlacePredictions(request, (results, status) => {
            console.log("getPlacePredictions results:", results, "Status:", status);
            callback(results, status);
          });
        }
      }, 200),
    []
  );

  // Fetch predictions when the input value changes.
  React.useEffect(() => {
    let active = true;
    if (!serviceReady) return;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = value ? [value] : [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [serviceReady, value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-address-autocomplete"
      style={{ marginBottom: '20px', display: 'flex' }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={props.onLocationAddr}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        props.onsetLocationAddr(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        props.onsetLocationAddr(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Pickup Location" variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}
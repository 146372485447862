import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import Works from './works';
 
const handleDragStart = (e) => e.preventDefault();
const responsive = {
    0: { items: 1 },
    568: { items: 1},
    768: { items: 1},
    1024: { items: 1 },
};

const items = [<div className="col-md-6 col-sm-12">                    
<p className="review-title-para">I was completely surprised and thrilled from the very first time I used their service. They were prompt and friendly, and their work was completed with military precision. I couldn’t be happier with the results, and I am equally satisfied every time I place an order. I’m proud to say that I am a repeat client.</p>
<h3 className="review-title-name">Paddy Slyne<br/>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
</h3>
<p className="review-from">Google Review</p>
</div>,
<div className="col-md-6 col-sm-12">                    
<p className="review-title-para">I used them for the first time, and it was absolutely awesome. Their customer service is exceptional—they should teach a course on it! I’ll be a regular customer from now on and highly recommend them.</p>
    <h3 className="review-title-name">Dave Sullivan<br/>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
        <i className="fa fa-star review-star"></i>
    </h3>
<p className="review-from">Facebook Review</p>
</div>

];

const ReviewMobile = () => {
    const [slideNum,setslideNum] =  React.useState(0)
    const slideTo =(activeIndex) => {
        setslideNum(activeIndex.slide)
    }
  return (
    <div className="container-fluid review-container mobileView">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="review-section-title">Testimonials
                    </h1>
                </div>
                <br/>
                <div className="col-md-12 col-sm-12">
                    <AliceCarousel 
                    autoPlay={true}
                    autoPlayInterval={12000}
                    animationDuration={400}
                    autoPlayStrategy={'none'}
                    animationType="fadeout"
                    infinite
                    touchTracking={false}
                    disableDotsControls
                    disableButtonsControls
                    mouseTracking items={items}
                    onSlideChanged={slideTo}
                    responsive={responsive}    
                />
            </div>
        </div>
    </div>
  );
}

export default ReviewMobile;
import React, { useState } from "react";
import "../../assets/sass/scheduler.scss";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import DateTimePickerScheduler from "./datetimepicker";
import axios from "axios";
import moment from "moment";
import GoogleAddress from "./GoogleAddress";
import Flippbackground from "../../images/flippBackground.svg";

// Import the global contact info
import contactInfo from "../config/contactInfo";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const Scheduler = (props) => {
  const classes = useStyles();
  const [successContainer, setsuccessContainer] = useState(false);
  const [PickupFinal, setPickupFinal] = useState(false);
  const [respMsg, setrespMsg] = useState("Processing");
  const [showEarnPage, setshowEarnPage] = useState(false);
  const [value, onChange] = useState(moment().format("yyyy-MM-DDTHH:mm"));
  // LocationAddr will be updated by GoogleAddress (as an object from Autocomplete)
  const [LocationAddr, setLocationAddr] = useState("");
  const [SchedulingTime, setSchedulingTime] = useState(
    moment({ hour: 8, minute: 0, second: 0 }).add(1, "days")
  );
  const [placeholderTime, setplaceholderTime] = useState(false);
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [EmailId, setEmailId] = useState("");
  const [userNotes, setuserNotes] = useState("");
  const [userCoupon, setuserCoupon] = useState("");
  const [correctTime, setcorrectTime] = useState(true);
  const [phoneUser, setphoneUser] = useState("");
  const [errorMsg, seterrorMsg] = useState(null);
  const [respIcon, setrespIcon] = useState("process");
  const [bookingSlot, setbookingSlot] = useState("07:30:00");

  // Helper function to check if an address appears complete.
  function isAddressComplete(address) {
    if (!address) return false;
    const addrStr = typeof address === "string" ? address : address.description;
    // Check for at least a number and some letters.
    return /\d+/.test(addrStr) && /[A-Za-z]+/.test(addrStr);
  }

  function schedulePickupNext() {
    let dr = new Date(SchedulingTime);
    if (
      SchedulingTime.length < 10 ||
      correctTime === false ||
      dr.getHours() < 0 ||
      dr.getHours() > 24
    ) {
      seterrorMsg("Please Select Correct Time!");
      return;
    }
    // Validate location – if it's a string that’s too short or an object missing a description.
    if (
      (typeof LocationAddr === "string" && LocationAddr.length <= 10) ||
      (typeof LocationAddr === "object" && !LocationAddr.description)
    ) {
      seterrorMsg("Please Enter a valid location!");
      return;
    }
    seterrorMsg(null);
    setPickupFinal(true);
  }

  const changeTime = (date) => {
    if (date.hour() < 0 || date.hour() > 24) {
      setSchedulingTime(date);
      setcorrectTime(false);
      seterrorMsg("Please Select Time from 12:00 AM to 11:50 PM !");
    } else {
      setcorrectTime(true);
      setSchedulingTime(date);
      seterrorMsg(null);
    }
  };

  const handleShiftChange = (event) => {
    setbookingSlot(event.target.value);
  };

  function schedulePickupFinal() {
    if (
      FirstName.length <= 1 ||
      LastName.length <= 1 ||
      EmailId.length <= 1 ||
      phoneUser.length <= 6
    ) {
      seterrorMsg("Please Fill Mandatory Fields !");
      return;
    }
    const firstName = FirstName;
    const lastName = LastName;
    // Updated time formatting: e.g., "2025-03-10T07:30:00"
    const time = `${SchedulingTime.format("YYYY-MM-DD")}T${bookingSlot}`;
    let location = "";
    if (typeof LocationAddr === "object") {
      location = LocationAddr.description;
    } else {
      location = LocationAddr;
    }
    const email = EmailId;
    const phone = phoneUser;
    const notes = userNotes;
    let coupon = userCoupon;
    if (props?.code) {
      coupon = coupon + " - " + props.code;
    }
    // Use fallback for API URL if not defined.
    const apiBaseUrl = process.env.GATSBY_API_URL || "/.netlify/functions";
    const url = apiBaseUrl + "/acuity";
    const mainsite = process.env.GATSBY_MAINSITE
      ? process.env.GATSBY_MAINSITE
      : "toronto";

    const data = {
      mainsite: mainsite,
      fname: firstName,
      lname: lastName,
      email: email,
      time: time,
      location: location,
      phone: phone,
      notes: notes,
      coupon: coupon,
    };

    const headers = {
      "Access-Control-Allow-Origin": "*",
    };

    axios
      .post(url, data, { headers: headers })
      .then((res) => {
        if (res.data.error === undefined) {
          setrespIcon("success");
          setrespMsg("Laundry Pick Up Scheduled");
        } else {
          setrespIcon("error");
          if (res.data.error === "no_available_calendar") {
            setrespMsg(
              `Oops! Please select a later pickup time. If you need assistance, send us a text at ${contactInfo.phone}.`
            );
          } else {
            setrespMsg(res.data.message);
          }
        }
      })
      .catch((err) => {
        console.error(
          "Error scheduling pickup:",
          err.response ? err.response.data : err
        );
        setrespIcon("error");
        setrespMsg("An error occurred while scheduling your pickup.");
      });
    setsuccessContainer(true);
    setPickupFinal(true);
  }

  function schedulePickupAgain() {
    setsuccessContainer(false);
    setPickupFinal(false);
    setrespIcon("process");
    setrespMsg(null);
  }

  function Earn() {
    setshowEarnPage(true);
  }
  async function Wash() {
    setshowEarnPage(false);
  }

  return (
    <div className="row-no-gutters">
      <div
        className={
          props?.code
            ? 'form-container scheduler flippCode'
            : 'form-container scheduler'
        }
      >
        <Card className={'card-scheduler'}>
          <Row className="row-no-gutters">
            {/* Add "final-confirmation" class when successContainer is true */}
            <div className={`form-bg ${PickupFinal ? "" : "schedule-view"} ${successContainer ? "final-confirmation" : ""}`}>
              {successContainer === true ? (
                <div
                  className="successMessage"
                  style={{ transform: 'translateY(20%)' }}
                >
                  <div
                    className="successContainer"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div className={'circle-border ' + respIcon}></div>
                    <div className={'circle'}>
                      {respIcon === 'success' ? (
                        <div className="success"></div>
                      ) : (
                        <i
                          className={
                            'fa fa-exclamation-triangle fa-2x ' + respIcon
                          }
                          style={{
                            fontSize: '30px',
                            transform: 'translate(50%, 50%)',
                          }}
                        ></i>
                      )}
                    </div>
                  </div>
                  {respIcon === 'success' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#000',
                        textAlign: 'center',
                        marginBottom: '10px',
                        marginTop: '15px',
                      }}
                    >
                      Please expect the driver to be there between{' '}
                      {bookingSlot === '07:30:00'
                        ? ' 7:30 AM - 8:30 AM '
                        : bookingSlot === '09:30:00'
                        ? ' 9:30 AM - 10:30 AM '
                        : ' 11:30 AM - 12:30 PM '}
                    </div>
                  )}
                  {respIcon === 'success' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#000',
                        textAlign: 'center',
                        marginBottom: '10px',
                        marginTop: '10px',
                      }}
                    >
                      The driver will call you before arrival
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#000',
                      textAlign: 'center',
                      marginBottom: '10px',
                      marginTop: '10px',
                    }}
                  >
                    {respMsg}
                  </div>
                  <br />
                  <div
                    className="bottomAction"
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <div style={{ marginRight: '0px' }}>
                      {respMsg !== null &&
                        (respIcon === 'error' ? (
                          <Button
                            className="btn square dark"
                            onClick={schedulePickupAgain}
                          >
                            Try Again
                          </Button>
                        ) : (
                          <Button
                            className="btn square dark"
                            onClick={schedulePickupAgain}
                          >
                            Book Again
                          </Button>
                        ))}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {PickupFinal === false ? (
                    <>
                      {/* Tagline remains separate */}
                      <div
                        className="container-btn"
                        style={{
                          marginBottom: '0px',
                          padding: '5px 0px 0px 15px',
                        }}
                      >
                        <p className="introText">
                          We pick up your laundry and return it clean, folded, and sorted<b>—all on the same day!</b>
                        </p>
                      </div>
                      <div className="container-form">
                        {showEarnPage === false ? (
                          <Col className="col-md-12 col-lg-12">
                            {/* Header remains separate */}
                            <h2
                              className="form-title"
                              style={{ paddingBottom: '15px' }}
                            >
                              Schedule a Pickup Now
                            </h2>
                            <div className="form-row">
                              <GoogleAddress
                                onLocationAddr={LocationAddr}
                                onsetLocationAddr={setLocationAddr}
                              />
                            </div>
                            {errorMsg !== null && (
                              <div className="form-row">
                                <p className="errorMesg">
                                  <i
                                    className="fa fa-info"
                                    style={{
                                      border: '1px solid red',
                                      borderRadius: '50%',
                                      padding: '2px 6px',
                                    }}
                                  ></i>
                                  &nbsp;&nbsp;&nbsp;{errorMsg}
                                </p>
                              </div>
                            )}
                            <div className="form-row">
                              <div className="timesRow">
                                <DateTimePickerScheduler
                                  renderType="times"
                                  bookingSlot={bookingSlot}
                                  handleShiftChange={handleShiftChange}
                                />
                              </div>
                            </div>
                            <div className="form-row bottomAction">
                              <div style={{ flex: '1', marginRight: '15px' }}>
                                <DateTimePickerScheduler
                                  renderType="date"
                                  value={SchedulingTime}
                                  onChange={changeTime}
                                />
                              </div>
                              <Button
                                className="btn square dark"
                                onClick={schedulePickupNext}
                              >
                                Next
                              </Button>
                            </div>
                          </Col>
                        ) : (
                          <Col>
                            <h3>Coming Soon</h3>
                          </Col>
                        )}
                      </div>
                    </>
                  ) : (
                    // "Some more Info" section
                    <div className="container-form some-more-info">
                      <Col className="col-md-12 col-lg-12">
                        <div className="form-row">
                          <h2 className="form-title">Some More Info</h2>
                        </div>
                        {/* Group first and last name in one row */}
                        <div className="form-row two-columns">
                          <TextField
                            id="userFirstName"
                            label="First name"
                            variant="outlined"
                            className="userField firstName"
                            value={FirstName}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setFirstName(event.target.value)}
                          />
                          <TextField
                            id="userLastName"
                            label="Last name"
                            variant="outlined"
                            className="userField lastName"
                            value={LastName}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setLastName(event.target.value)}
                          />
                        </div>
                        {/* Group email and phone in one row */}
                        <div className="form-row two-columns">
                          <TextField
                            id="userEmail"
                            label="Email Id"
                            variant="outlined"
                            className="userField userEmail"
                            value={EmailId}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setEmailId(event.target.value)}
                          />
                          <TextField
                            id="userPhone"
                            label="Phone No."
                            variant="outlined"
                            className="userField userPhone"
                            value={phoneUser}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setphoneUser(event.target.value)}
                          />
                        </div>
                        {/* Important Notice */}
                        <div className="important-notice">
                          <p>Important Notice</p>
                          <ul>
                            <li>
                              We do not inspect individual garment labels.
                            </li>
                            <li>
                              Separate items not for the dryer; add special instructions (e.g., "do not dry") in the designated field.
                            </li>
                            <li>We do not service designer clothing.</li>
                            <li>Wash & fold only; no dry cleaning or ironing offered.</li>
                          </ul>
                        </div>
                        <div className="form-row">
                          <TextField
                            id="userNotes"
                            label="Special Instructions"
                            variant="outlined"
                            placeholder="Enter apt/buzzer, wash preferences, etc."
                            className="userField userNotes"
                            value={userNotes}
                            InputLabelProps={{ shrink: true }}
                            onChange={event => setuserNotes(event.target.value)}
                          />
                        </div>
                        <div className="form-row">
                          <TextField
                            id="userCoupon"
                            label="Coupon Code"
                            variant="outlined"
                            className="userField userCoupon"
                            value={userCoupon}
                            InputLabelProps={{ shrink: true }}
                            onChange={event =>
                              setuserCoupon(event.target.value)
                            }
                          />
                        </div>
                        {errorMsg !== null && (
                          <div className="form-row">
                            <p className="errorMesg">
                              <i
                                className="fa fa-info"
                                style={{
                                  border: '1px solid red',
                                  borderRadius: '50%',
                                  padding: '2px 6px',
                                }}
                              ></i>
                              &nbsp;&nbsp;&nbsp;{errorMsg}
                            </p>
                          </div>
                        )}
                        <div className="form-row bottomAction">
                          <div
                            className="sch-btn"
                            style={{ display: 'flex', width: '100%' }}
                          >
                            <Button
                              className="btn square light"
                              onClick={schedulePickupAgain}
                            >
                              Go Back
                            </Button>
                            <Button
                              className="btn square dark"
                              onClick={schedulePickupFinal}
                              style={{ marginLeft: 'auto' }}
                            >
                              Schedule
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </div>
                  )}
                </>
              )}
            </div>
          </Row>
        </Card>
        {props?.code && (
          <div className="flippbackground">
            <img src={Flippbackground} alt="Flipp Meets Laundrytogo" />
            <br />
            <p className="flippIntroPara desktopView">
              A gesture of appreciation. 40lb/month (approximately 2 full bags)
              free for all Flipp parents and families.
              <br />
              $1.49/lb for any weight over 40 lbs of laundry.
            </p>
            <p className="flippIntroPara mobileView">
              <span style={{ fontSize: '21px' }}>
                A gesture of appreciation.
              </span>
              <br />
              <br />
              <span style={{ fontSize: '16px' }}>
                40lb/month free for all Flipp parents and families. $1.49/lb for
                any weight over 40 lbs of laundry.
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scheduler;

import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import img1 from "../../images/slider_new_1.svg"; // Time
import img3 from "../../images/slider_new_2.svg"; // Old Lady
import img2 from "../../images/slider_new_3.svg"; // Garbage Bags
import img4 from "../../images/slider_new_4.svg"; // Delivery
import 'react-alice-carousel/lib/alice-carousel.css';
import Works from './works';

const handleDragStart = (e) => e.preventDefault();

const responsive = {
  0: { items: 1 },
  568: { items: 1 },
  768: { items: 1 },
  1024: { items: 1 },
};

const items = [
  <div className="sliderTextWrapper" key="item1">
    <div className="col-md-4 Active sliderText">
      <h6 style={{ fontSize: '32px', fontWeight: '600' }}>
        Never Worry About Laundry Day Again!
      </h6>
      <span style={{ fontSize: '22px', color: '#000', fontWeight: '400', lineHeight: '32px' }}>
        Sit back and relax while we handle the chore.
      </span>
    </div>
    <p className="col-md-7">
      <img
        src={img1}
        alt="Laundry day"
        onDragStart={handleDragStart}
        className="sliderImages sliderImages1"
      />
    </p>
  </div>,
  <div className="sliderTextWrapper" key="item2">
    <div className="col-md-4 col-md-offset-1 Active sliderText">
      <h6 style={{ fontSize: '32px', fontWeight: '600' }}>
        We Simplify Laundry for You
      </h6>
      <span style={{ fontSize: '22px', color: '#000', fontWeight: '400', lineHeight: '32px' }}>
        Your laundry returns clean, neatly folded, and perfectly sorted. All you have to do is put it away.
      </span>
    </div>
    <p className="col-md-7">
      <img
        src={img3}
        alt="Laundry simplified"
        onDragStart={handleDragStart}
        className="sliderImages sliderImages2"
      />
    </p>
  </div>,
  <div className="sliderTextWrapper" key="item3">
    <div className="col-md-5 Active sliderText">
      <h6 style={{ fontSize: '32px', fontWeight: '600' }}>
        Only $1.89 per lb
      </h6>
      <span style={{ fontSize: '22px', color: '#000', fontWeight: '400', lineHeight: '32px' }}>
        Bag up your laundry and let us take care of the rest!
      </span>
    </div>
    <p className="col-md-7">
      <img
        src={img2}
        alt="Affordable pricing"
        onDragStart={handleDragStart}
        className="sliderImages sliderImages3"
      />
    </p>
  </div>,
  <div className="sliderTextWrapper" key="item4">
    <div className="col-md-5 Active sliderText">
      <h6 style={{ fontSize: '32px', fontWeight: '600' }}>
        Same-Day Service
      </h6>
      <span style={{ fontSize: '22px', color: '#000', fontWeight: '400', lineHeight: '32px' }}>
        Delivered straight to your door.
      </span>
    </div>
    <p className="col-md-7">
      <img
        src={img4}
        alt="Same day delivery"
        onDragStart={handleDragStart}
        className="sliderImages sliderImages4"
      />
    </p>
  </div>,
];

// Reverse the items array so that manual dragging works as expected.
const reversedItems = [...items].reverse();

const Gallery = () => {
  const [slideNum, setSlideNum] = React.useState(0);
  const carouselRef = React.useRef(null);

  // Set up a custom auto-scroll that goes left-to-right (i.e. calling slidePrev)
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (carouselRef.current) {
        carouselRef.current.slidePrev();
      }
    }, 5500);
    return () => clearInterval(intervalId);
  }, []);

  const slideTo = (activeIndex) => {
    setSlideNum(activeIndex.slide);
  };

  return (
    <div className="row section-carousel works">
      <h2 className="col-md-12 heading m-b-10">How it Works ?</h2>

      <div className="col-md-12 col-sm-12">
        <AliceCarousel
          ref={carouselRef}
          // Turn off the built-in autoPlay so our timer controls auto-scrolling.
          autoPlay={false}
          animationDuration={2000}
          animationType="slide"
          infinite
          touchTracking={true}
          disableDotsControls
          disableButtonsControls
          mouseTracking
          items={reversedItems}
          onSlideChanged={slideTo}
          responsive={responsive}
        />
      </div>

      {/*
      Uncomment below if you need additional content for mobile view
      <div className="col-sm-12 sliderTextWrapper mobileView">
        <Works />
      </div>
      */}
    </div>
  );
};

export default Gallery;
